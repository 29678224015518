export default {
  tab: {
    untitled: 'No Page'
  },
  contextmenu: {
    refresh: 'Refresh',
    refreshAll: 'refreshAll',
    close: 'Close',
    closeLefts: 'Close Lefts',
    closeRights: 'Close Rights',
    closeOthers: 'Close Others'
  },
  msg: {
    keepLastTab: 'Keep Last Tab',
    i18nProp: 'Please provide "i18n" method to handle internationalized content'
  }
}
