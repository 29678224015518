import Vue from "vue";
import Router from "vue-router";
import VueHead from "vue-head";

import Config from '@/conf/Config.js';
import axios from 'axios';



Vue.use(VueHead);
Vue.use(Router);

/* eslint-disable */
/** sweet alert */
import swal from "@/assets/swal/sweet-alert.min.js";
require("@/assets/swal/sweet-alert.css");
require("@/assets/swal/theme.css");

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/login",
            name: "Login",
            component: () => import("./views/public/Login.vue")
        },
        {
            path: "/home",
            name: "Dashboard",
            component: () => import('./views/admin/Induk.vue'),
            meta: {
                auth: true,
                title : 'Dashboard'
            },
            children: [
                {
                    path: "/",
                    name: "Main",
                    component: () => import("./views/admin/Dashboard.vue"),
                    meta: {
                        auth: true,
                        title : 'Main',
                        tips: 'Dashboard',
                        icon: 'fa fa-home', 
                        closable: true,
                        tabClass: 'w3-orange',
                    }
                },
                {
                    path: "/profile",
                    name: "Profile",
                    component: () => import("./views/admin/Profile.vue"),
                    meta: {
                        auth: true,
                        title : 'Profile',
                        icon: 'fa fa-user',
                        closable: true, 
                    }
                },
                {
                    path: "/petugas",
                    name: "petugas",
                    component: () => import("./views/master/Petugas.vue"),
                    meta: {
                        auth: true,
                        title : 'Petugas',
                        icon: 'fa fa-users',
                        closable: true, 
                    }
                },
                {
                    path: "/layanan",
                    name: "layanan",
                    component: () => import("./views/master/Pelayanan.vue"),
                    meta: {
                        auth: true,
                        title : 'Pelayanan',
                        icon: 'fa fa-archive',
                        closable: true, 
                    }
                },
                {
                    path: "/bed",
                    name: "bed",
                    component: () => import("./views/master/Bed.vue"),
                    meta: {
                        auth: true,
                        title : 'Bed',
                        icon: 'fa fa-bed',
                        closable: true, 
                    }
                },
                {
                    path: "/info",
                    name: "info",
                    component: () => import("./views/master/Info.vue"),
                    meta: {
                        auth: true,
                        title : 'Info',
                        icon: 'fa fa-info',
                        closable: true, 
                    }
                },
				{
                    path: "/video",
                    name: "video",
                    component: () => import("./views/master/Video.vue"),
                    meta: {
                        auth: true,
                        title : 'Info',
                        icon: 'fa fa-video-camera',
                        closable: true, 
                    }
                },
                {
                    path: "/jadwal",
                    name: "jadwal",
                    component: () => import("./views/transaksi/Jadwal.vue"),
                    meta: {
                        auth: true,
                        title : 'Jadwal',
                        icon: 'fa fa-list',
                        closable: true, 
                    }
                },
                {
                    path: "/pasien",
                    name: "pasien",
                    component: () => import("./views/transaksi/Pasien.vue"),
                    meta: {
                        auth: true,
                        title : 'Pasien',
                        icon: 'fa fa-users',
                        closable: true, 
                    }
                },
                {
                    path: "/antrian",
                    name: "antrian",
                    component: () => import("./views/transaksi/Antrian.vue"),
                    meta: {
                        auth: true,
                        title : 'Antrian',
                        icon: 'fa fa-gears',
                        closable: true, 
                    }
                },
				{
				    path: "/pengaduan",
				    name: "pengaduan",
				    component: () => import("./views/transaksi/Pengaduan.vue"),
				    meta: {
				        auth: true,
				        title : 'Pengaduan',
				        icon: 'fa fa-info',
				        closable: true, 
				    }
				},
                {
                    path: "/lapantrian",
                    name: "lapantrian",
                    component: () => import("./views/reports/Lapantrian.vue"),
                    meta: {
                        auth: true,
                        title : 'Laporan',
                        icon: 'fa fa-area-chart',
                        closable: true, 
                    }
                }
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {
    try {
        if (to.matched.some(record => record.meta.auth)) {
            axios.defaults.baseURL = Config.apiUrl;
            axios.defaults.headers.common['authorization'] = localStorage.eltamatoken;
            axios.post('/tproject/admin/checklogin',
            ).then(function (response) {
                if (response.data.success == true) {
                    try {
                        next();
                    } catch (err) {
                        console.log(err);
                        next("/login");
                    }
                } else {
                    next("/login");
                }
            });
        } else {
            // console.log(to.matched.length);
            // if (to.matched.length > 0){
            //     if (to.matched[0].name == 'Login') {
            //         axios.defaults.baseURL = Config.apiUrl;
            //         axios.defaults.headers.common['authorization'] = localStorage.eltamatoken;
            //         axios.post('/tproject/admin/checklogin',
            //         ).then(function (response) {
            //             if (response.data.success == true) {
            //                 try {
            //                     next();
            //                 } catch (err) {
            //                     console.log(err);
            //                     next("/login");
            //                 }
            //             } else {
            //                 next("/login");
            //             }
            //         });
            //     }
            // }

            if (to.matched.length > 0) {
                next();
            } else {
                next("/home");
            }
        }
    } catch (error) {
        console.log('router error1', error);
    }
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        
    }
    next()
});


router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    // NProgress.done()
});

export default router;