import Vue from 'vue';
// Socket.io
import config from '@/conf/Config.js';
import VueSocketIO from 'vue-socket.io';
import socketio from 'socket.io-client';
const SocketInstance = socketio.connect(config.apiUrl);
Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketInstance
}));


import App from './App.vue';
import router from './router';
import mixin from  './mixin.js';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueMask from 'v-mask'

// Untuk Form
import {ModelListSelect} from '@/components/vue-select';
import VueDraggableResizable from "vue-draggable-resizable";
import {Money} from 'v-money';
import Paging from "@/components/Paging.vue";
import Toolbar from "@/components/Toolbar.vue";
import DeLoading from "@/components/DeLoading.vue";
import DeObject from '@/components/Form/DeObject.vue';
import VueScrollingTable  from '@/components/tablescroll/VueScrollingTable.vue';
import HeaderReport from '@/components/HeaderReport.vue';
import FooterReport from '@/components/FooterReport.vue';
require("@/assets/css/style-admin.css");
import DatePicker from '@/components/DatePicker.vue';
import Grid from 'vue-js-grid';
Vue.component("ModelListSelect",ModelListSelect);
Vue.component("VueDraggableResizable",VueDraggableResizable);
Vue.component("Money",Money);
Vue.component("Paging",Paging);
Vue.component("Toolbar",Toolbar);
Vue.component("DeObject",DeObject);
Vue.component("DeLoading",DeLoading);
Vue.component("HeaderReport",HeaderReport);
Vue.component("FooterReport",FooterReport);
// Vue.component(moment);
Vue.component("VueScrollingTable",VueScrollingTable);
Vue.component("DatePicker",DatePicker);


import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

Vue.use(Grid);
Vue.use(VueMask);
Vue.use(VueMoment, {
    moment,
});

import RouterTab from  './routerTab';

Vue.config.productionTip = false;
Vue.use(RouterTab);

Vue.use(VueToast, {
  position: 'top',
  duration : 4000
});
// import lokalmixin from  './lokal-mixin.js';
// mixin.methods['mixinproject'] = ()=> {return lokalmixin.methods };
Vue.mixin(mixin);
let vm = new Vue({
    el: '#app',
    router,
    render: h => h(App)
}).$mount('#app');
global.vm=vm;