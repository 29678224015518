/* eslint-disable */
import config from '@/conf/Config.js';
import md5 from  './assets/doevent/md5.js';
import axios from 'axios';
// import VueMoment from 'vue-moment';
axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common['authorization'] = localStorage.eltamatoken;

export default {
    data(){
        return {
            appName : config.appName,
            perPage : config.perpage,
            apiUrl : config.apiUrl,
        }
    },
    methods:{
        slice(array, start, end){
            let length = array == null ? 0 : array.length;
            if (!length) {
                return [];
            }
            start = start == null ? 0 : start;
            end = end === undefined ? length : end;
            if (start < 0) {
                start = -start > length ? 0 : length + start;
            }
            end = end > length ? length : end;
            if (end < 0) {
                end += length;
            }
            length = start > end ? 0 : (end - start) >>> 0;
            start >>>= 0;
            let index = -1;
            const result = new Array(length);
            while (++index < length) {
                result[index] = array[index + start];
            }
            return result;
        },
        setFilterHeaders(orders){
            const self = this;
            orders.fields.map(function(val){
                if(val.sort!=undefined){
                    if(val.sort.asc!="-"){
                        if(val.sort.asc=="asc"){
                            val.sort.asc="-";
                        }else if(val.sort.asc=="desc"){
                            val.sort.asc="asc";
                        }
                        self.setFilterHeader(val,orders);
                    }
                }
            });
        },
        setFilterHeader(dt,order){
            if(dt['sort']['status']){
                let filter = (dt.fieldFind==undefined)?dt.key:((dt.fieldFind == '')?  dt.key : dt.fieldFind);
                filter = filter + ' ' + dt['sort']['asc'];
                order['order'] = order['order'].replace(","+filter,'');
                order['order'] = order['order'].replace(filter,'');
                if(order['order'][0]==','){
                    order['order'] = order['order'].substring(1, order['order'].length);
                }
                dt['sort']['asc'] = (dt['sort']['asc']=='asc')?'desc':((dt['sort']['asc']=='-')?'asc':'-');
                filter = (dt.fieldFind==undefined)?dt.key: (dt.fieldFind == ''?  dt.key : dt.fieldFind);
                filter = filter + ' ' + dt['sort']['asc'] ;
                if(dt['sort']['asc']!='-') {
                    if(order['order']!=''){
                        order['order'] = order['order']+","+filter;
                    }else{
                        order['order'] += filter;
                    }
                }
            }else{
                return false;
            }
        },
        getNow(format){
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();
            if(format=='id'){
                today = `${dd}-${mm}-${yyyy}`;
            }else{
                today = `${yyyy}-${mm}-${dd}`;
            }
            return today;
        },
        showLog(){
            if(process.env.NODE_ENV !== 'production'){
                return Array.prototype.slice.call(arguments);
            }else{
                return false;
            }
        },
        showLoading(title){
            this.setTextLoading(title);
            document.getElementById('idLoading').style.display='block';
        },
        setTextLoading(title){
            document.getElementById('txt-loading').innerHTML = title;
        },
        hideLoading(){
            document.getElementById('idLoading').style.display='none';
        },
        getValid(rec,rules){
            let isValid=true;
            let self = this;
            for(let dt in rec){
                // console.log(rec[dt]);
                let field = self.findObj(rules,"field",dt);
                // console.log(dt, field);
                if(field!=undefined){
                    if(field.rules.required ){
                        if(["String","text","select"].includes(field.rules.type)){
                            if(rec[dt].length==0){
                                isValid = false;
                                console.log(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                                vm.$toast.error(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                            }else if(rec[dt].length< field.rules["min-length"]){
                                isValid = false;
                                vm.$toast.error(self.setToast('Error', `${field.title} harus lebih besar ${field.rules["min-length"]} huruf`  ,'',300));
                                console.log(self.setToast('Error', `${field.title} harus lebih besar ${field.rules["min-length"]} huruf`  ,'',300));
                            }else if(rec[dt].length> field.rules["max-length"]){
                                isValid = false;
                                vm.$toast.error(self.setToast('Error', `${field.title} harus lebih besar dari ${field.rules["max-length"]} huruf`  ,'',300));
                                console.log(self.setToast('Error', `${field.title} harus lebih besar dari ${field.rules["max-length"]}`  ,'',300));
                            }
                        }else{
                            if(rec[dt].length==0){
                                isValid = false;
                                vm.$toast.error(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                                console.log(self.setToast('Error', `${field.title} Tidak boleh kosong`  ,'',300));
                            }
                        }
                    }
                }
            }
            return isValid;
        },
        setToast(title, content, icon, width){
            icon = (icon==''?'':icon);
            width = (width==''?'200':width);
            let hd = (icon!="")?`<div class="w3-col l2">
            <i class="fa ${icon}"></i>
        </div>`:'';
            let str  = `<div class="w3-row" style="width:${width}px;">
                ${hd}
                <div class="w3-rest">
                    <b><u>${title}</u></b>
                    <div>${content}</div>
                </div>
            </div>`;
            return str;
        },
        firstUpperCase(kata) {
            return kata.charAt(0).toUpperCase() + kata.slice(1)
        },        
        toDateServer:function(value){
            let data = value.split("/");
            return data[2]+"-"+data[1]+"-"+data[0];
        },
        apiGetParams(params){
            let keys = Object.keys(params);
            var parameters="";
            keys.forEach(function(key){
                console.log(params[key])
                var value = params[key];
                parameters += `${key}=${value}&`
            });
            return parameters;
        },
        async favoritMenu(id){
            axios.post('/post/admin/favoritmenu',{mn_id:id})
        },
        async getCode(){
            return md5(Math.random().toString()+'doevent');
        },
        async deAsync(url,params){
            let obj = await axios.post(url,params);
            return obj;
        },
        dePost(url, params, success = function(dt) {}, errorFn = function(dt) {} ){
            axios.post(url,params).then(success).catch(errorFn);
        },
		/**
		 * @param String url
		 * @param {Object} url
		 * @param function success
		 * @param function errorFn
		 */
        apiPost(url, params, success = function(dt) {}, errorFn = function(dt) {} ) {
            if(url.substring(0, 1)!="/"){
                url = "/" + url;
            }
            if(url.substring(0, 1)!="/"){
                url = "/" + url;
            }
            const controller = new AbortController();
            const timeoutId = setTimeout(function(){ 
                controller.abort();
                errorFn("Server tidak merespon " + url);
            }, 5000);
            fetch(this.apiUrl +  url, {
                method: "POST",
                headers: {authorization: localStorage.eltamatoken , "Content-Type": "application/json"},
                body: JSON.stringify(params),
            })
            .then((response) => {
                if (!response.ok) {
                    if (response.status == 401) {
                        vm.$router.push({ name: "Login" });
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                    try {
                        return response
                            .json()
                            .then((err) => Promise.reject(err));
                    } catch (e) {
                        return response
                            .text()
                            .then((err) => Promise.reject(err));
                    }
                }
                clearTimeout(timeoutId);
                return response.json();
            })
            .then(success)
            .catch(errorFn);
        },
		apiPublicPost(url, params, success = function(dt) {}, errorFn = function(dt) {} ) {
		    if(url.substring(0, 1)!="/"){
		        url = "/" + url;
		    }
		    if(url.substring(0, 1)!="/"){
		        url = "/" + url;
		    }
		    const controller = new AbortController();
		    const timeoutId = setTimeout(function(){ 
		        controller.abort();
		        errorFn("Server tidak merespon " + url);
		    }, 5000);
		    fetch(config.apiPublic +  url, {
		        method: "POST",
		        headers: {authorization: localStorage.eltamatoken , "Content-Type": "application/json"},
		        body: JSON.stringify(params),
		    })
		    .then((response) => {
		        if (!response.ok) {
		            if (response.status == 401) {
		                vm.$router.push({ name: "Login" });
		                return response
		                    .text()
		                    .then((err) => Promise.reject(err));
		            }
		            try {
		                return response
		                    .json()
		                    .then((err) => Promise.reject(err));
		            } catch (e) {
		                return response
		                    .text()
		                    .then((err) => Promise.reject(err));
		            }
		        }
		        clearTimeout(timeoutId);
		        return response.json();
		    })
		    .then(success)
		    .catch(errorFn);
		},
        async apiAsync(url, params) {
            if(url.substring(0, 1)!="/"){
                url = "/" + url;
            }
            const controller = new AbortController();
            const timeoutId = setTimeout(function(){ 
                controller.abort();
                errorFn("Server tidak merespon " + url);
            }, 5000);

            let response = await fetch(this.apiUrl +  url, {
                method: "POST",
                headers: {authorization: localStorage.eltamatoken, "Content-Type": "application/json" },
                body: JSON.stringify(params),
            });

            if (!response.ok) {
                if (response.status == 401) {
                    vm.$router.push({ name: "Login" });
                    return response
                        .text()
                        .then((err) => Promise.reject(err));
                }
                try {
                    return response
                        .json()
                        .then((err) => Promise.reject(err));
                } catch (e) {
                    return response
                        .text()
                        .then((err) => Promise.reject(err));
                }
            }
            clearTimeout(timeoutId);
            let obj = await response.json(); 
            return obj;
        },
        apiUpload: function(url, data, callback = function(json) {}, error = function(json) {}, progress = function(evt) {}) {
            var request = new XMLHttpRequest();
            request.open("POST", this.apiUrl +  url);
            request.onreadystatechange = function() {
                if (request.readyState === 4) {
                    if (request.status == 200 || request.status == 201) {
                        callback(request.responseText);
                    } else {
                        error(request.responseText);
                    }
                }
            };
            request.setRequestHeader("authorization", localStorage.eltamatoken);
            request.upload.addEventListener("progress", progress);
            request.send(data);
        },
        deDownloadFile(url, params, calback){
            let number = 0;
            if(url==''){
                url = this.setting.url + '/download';
            }
            // NProgress.set(0.0);
            axios(
                {
                    url: url,method: 'POST',
                    responseType: 'blob', 
                    data : params,
                    onDownloadProgress : function (progressEvent) {
                        number = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                        // console.log(number, progressEvent);
                        // NProgress.set(number/100);
                        if(number>=100){
                            // NProgress.set(1.0);
                        }
                    }
                }
            ).then(calback);
        },
        async deUploadFile(url,file, param){
            let _this = this;
            let formData = new FormData();
            formData.append("file", file);
            if (param!='') {
                param.map(function (value) {
                    formData.append(value.key,value.val)
                })
            }
            let number = 0;
            let dt = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: function( progressEvent ) {
                    number = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                    _this.setTextLoading(`Proses upload ... ${number} %`);
                }.bind(this)
            });
            return dt;
        },
        formatNumber(val,decimals=0){
            if(val===null || val==""){val=0;return;}
            if(val!==null && val!=""&& val!="."){
            let rawValue = (val).toString();
            var decimal;
            if( (rawValue).includes(".") ){
                val = ( (rawValue).split(".")[0] ).replace(/,/g,"");
                decimal = ((rawValue).split(".")[1]).length==0?"00000":(rawValue).split(".")[1];
                decimal=decimal.slice(0,decimals);
            }else{
                val = (rawValue).replace(/,/g,"");
                decimal="00";
            }
            let formattedVal = parseFloat(val).toLocaleString('en');
            val=formattedVal+((rawValue).includes(".")?`${decimals==0?"":"."+decimal}`:"");
            }
            return val;
        },
        number(val){
            let data=val;
            try{
                data =  parseFloat( (val.toString()).replace(/,/g,"") );
            }catch(e){
                data =  0;
            }
            return data;
            return this.formatNumber(data);
        },
        eventGrid(self, grid, selectedRow, recs){
            window.addEventListener('keydown', function(evt) {
                // self.showLog(self.$route.name)
                // self.showLog(self.$options.name);
                // self.showLog(self[grid]);
                if(self.$route.name == self.$options.name && self[grid]){
                    let baris =  document.getElementById(grid+'-'+self[selectedRow]) ;
                    switch (evt.code) {
                        case 'ArrowUp':
                            if(self[selectedRow]>0){
                                baris.previousElementSibling.focus();
                                self[selectedRow]--;
                            }
                            break;
                        case 'ArrowDown' :
                            if(self[selectedRow]<(self[recs].length-1)){
                                baris.nextElementSibling.focus();
                                self[selectedRow]++;
                            }
                            break;
                    }
                }
            });
        },
        getGridColumnmAutoWidth(id, kolom, retur){
            let x = document.getElementById(id);
        let lebar = 0;
            kolom.map(function(val){
                if(val.width!='' && val.width!='auto'){
                    
                    lebar = lebar + parseInt(val.width.replace("px", ""));
                    val['width-numeric'] = parseInt(val.width.replace("px", ""));
                }
            });
            if(!retur){
                kolom.map(function(val){
                    if(val.width=='auto'){
                        lebar = (parseFloat(x.offsetWidth) - parseFloat(lebar)-68);
                        if (lebar<0){
                            lebar = 150;
                        }
                        val.width = lebar + "px";
                        val['width-numeric'] = lebar;
                    }
                });
            }else{
                return (parseFloat(x.offsetWidth) - parseFloat(lebar)-68);
            }
        },
        incDay(tgl, plus){
            var tzOff = tgl.getTimezoneOffset() * 60 * 1000,
                t = tgl.getTime(),
                d = new Date(),
                tzOff2;

            t += (1000 * 60 * 60 * 24) * plus;
            d.setTime(t);

            tzOff2 = d.getTimezoneOffset() * 60 * 1000;
            if (tzOff != tzOff2) {
                var diff = tzOff2 - tzOff;
                t += diff;
                d.setTime(t);
            }

            return d;
        },
		incTime(tgl, plus){
			let now = new Date(`${tgl}`);
			now.setMinutes(now.getMinutes() + plus); // timestamp
			return new Date(now); 
		},
        lastDayInMonth(bln,thn){
            return new Date(thn, bln, 0).getDate();
        },
        downloadFile(filename, text, type){
            // 'data:text/plain;charset=utf-8,'
            var element = document.createElement('a');
            element.setAttribute('href', type +";charset=utf-8,"+ encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        jsonToQuery(json) {
            let str = "";
            for (var pkey in json) {
                if (str == "") {
                    str = pkey + "=" + json[pkey];
                } else {
                    str = str + "&" + pkey + "=" + json[pkey];
                }
            }
            return str;
        },
        // export default  {    
        sethighlight(text, phrase) {
            if (phrase)
                text = text.replace(new RegExp('(' + phrase + ')', 'gi'), '<span class="w3-orange">$1</span>');
            return text;
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        setTerbilang(bilangan) {
            let kalimat = "";
            let angka = new Array('0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0');
            let kata = new Array('', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan');
            let tingkat = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun');
            bilangan = bilangan + "";
            let panjang_bilangan = bilangan.length ;
            if(panjang_bilangan > 15){
                kalimat = "Diluar Batas";
            }else{
                /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
                for(i = 1; i <= panjang_bilangan; i++) {
                    angka[i] = bilangan.substr(-(i),1);
                }
                
                var i = 1;
                var j = 0;
                
                /* mulai proses iterasi terhadap array angka */
                while(i <= panjang_bilangan){
                    subkalimat = "";
                    kata1 = "";
                    kata2 = "";
                    kata3 = "";
                    
                    /* untuk Ratusan */
                    if(angka[i+2] != "0"){
                        if(angka[i+2] == "1"){
                            kata1 = "Seratus";
                        }else{
                            kata1 = kata[angka[i+2]] + " Ratus";
                        }
                    }
                    
                    /* untuk Puluhan atau Belasan */
                    if(angka[i+1] != "0"){
                        if(angka[i+1] == "1"){
                            if(angka[i] == "0"){
                                kata2 = "Sepuluh";
                            }else if(angka[i] == "1"){
                                kata2 = "Sebelas";
                            }else{
                                kata2 = kata[angka[i]] + " Belas";
                            }
                        }else{
                            kata2 = kata[angka[i+1]] + " Puluh";
                        }
                    }
                    
                    /* untuk Satuan */
                    if (angka[i] != "0"){
                        if (angka[i+1] != "1"){
                            kata3 = kata[angka[i]];
                        }
                    }
                    
                    /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
                    if ((angka[i] != "0") || (angka[i+1] != "0") || (angka[i+2] != "0")){
                        subkalimat = kata1+" "+kata2+" "+kata3+" "+tingkat[j]+" ";
                    }
                    
                    /* gabungkan variabe sub kalimat (untuk Satu blok 3 angka) ke variabel kalimat */
                    kalimat = subkalimat + kalimat;
                    i = i + 3;
                    j = j + 1;
                }
                
                /* mengganti Satu Ribu jadi Seribu jika diperlukan */
                if ((angka[5] == "0") && (angka[6] == "0")){
                    kalimat = kalimat.replace("Satu Ribu","Seribu");
                }
            }
            return kalimat;
        },
        printHtml(textHtml, kertas, bentuk, margin){
            let idprint = "de-print";
            let element = document.getElementById(idprint);
            if(element!=null)
                element.parentNode.removeChild(element);

            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', idprint);
            iframe.setAttribute('name', idprint);
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('display', 'none');
            iframe.setAttribute('width', 0);
            iframe.setAttribute('height', 0);

            document.body.appendChild(iframe);
            let newWin = window.frames[idprint];
            textHtml = `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <link rel="stylesheet" href="./assets/css/w3.css">
                    <link rel="stylesheet" href="./assets/css/report.css">
                    <title>Document</title>
                    <style>
                    .pindah-halaman {page-break-after: always;}
                    @media print { 
                        @page { 
                            size: ${kertas} ${bentuk}; 
                            margin : ${margin};
                        }
                    }
                    @font-face {
                        font-family: barcodefont;
                        src: url(assets/font-code/BarcodeFont.ttf);
                    }
                    .debarcode{
                        font-family: barcodefont;
                    }
                    </style>
                </head>				
                <body onload="window.print()">
                    ${textHtml}
                </body>
            </html>`;
            newWin.document.write(textHtml);
            // console.log(textHtml);
            newWin.document.close();
            
        },
        setRomawi(x){
            let abil = ["", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
            if (x < 11)
                return abil[x];
            else if (x < 20)
                return abil[10] + this.setRomawi(x - 10) ;
            else if (x < 100)
                return this.setRomawi(x / 10)  + this.setRomawi(x % 10);
            else if (x < 200)
                return  this.setRomawi(x - 100);
            else if (x < 1000)
                return this.setRomawi(x / 100) + this.setRomawi(x % 100);
            else if (x < 2000)
                return  this.setRomawi(x - 1000);
            else if (x < 1000000)
                return this.setRomawi(x / 1000)  + this.setRomawi(x % 1000);
            else if (x < 1000000000)
                return this.setRomawi(x / 1000000)  + this.setRomawi(x % 1000000);
        },
        setFocus(nextElm){
            document.getElementById(nextElm).focus();
            return false;
        }
    }        
}
